<template>
  <Dialog :title="hunt.name" :buttons="buttons" @close="close">
    <CalendarDialogHeader
      :hunt-area="huntArea"
      :starts-at="hunt.startsAt"
      :ends-at="hunt.endsAt">
    </CalendarDialogHeader>

    <CalendarDialogCommentSection
      :comment="note"
      :isHtml="false"
      :empty-text="$t('calendar.noInvitationText')">
    </CalendarDialogCommentSection>

    <CalendarHuntDialogMemberSection />

    <Divider />

    <CalendarHuntDialogEventSection v-if="isFinished" />
    <CalendarHuntDialogAssignmentSection v-else />
  </Dialog>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import Dialog from '@/components/ui/dialog2/Dialog2.vue'

import CalendarDialogHeader from '../dialog/CalendarDialogHeader.vue'
import CalendarDialogCommentSection from '../dialog/CalendarDialogCommentSection.vue'

import CalendarHuntDialogMemberSection from './CalendarHuntDialogMemberSection.vue'
import CalendarHuntDialogEventSection from './CalendarHuntDialogEventSection.vue'
import CalendarHuntDialogAssignmentSection from './CalendarHuntDialogAssignmentSection.vue'

export default {
  components: {
    Dialog,
    CalendarDialogHeader,
    CalendarDialogCommentSection,
    CalendarHuntDialogMemberSection,
    CalendarHuntDialogEventSection,
    CalendarHuntDialogAssignmentSection
  },

  data () {
    return {
      huntArea: null
    }
  },

  computed: {
    ...mapGetters({
      hunt: 'calendar/selectedHunt/getHunt',
      note: 'calendar/selectedHunt/getNote',
      events: 'calendar/selectedHunt/getEvents'
    }),

    buttons () {
      let buttons = []

      if (!this.isFinished) {
        buttons.push({
          title: this.buttonTitle,
          type: 'primary',
          click: this.viewHunt
        })
      }

      return buttons
    },

    title () {
      return this.isFinished ? this.$i18n.t('calendar.huntingReport') : this.$i18n.t('calendar.hunt')
    },

    buttonTitle () {
      return this.isFinished ? this.$i18n.t('calendar.seeCompleteReport') : this.$i18n.t('calendar.seeCompletePlanning')
    },

    isFinished () {
      return this.hunt.status === 'finished'
    }
  },

  created () {
    this.readHuntArea()
  },

  methods: {
    ...mapActions({
      getHuntAreaById: 'huntarea/getById'
    }),

    ...mapMutations({
      setHunt: 'calendar/selectedHunt/setHunt'
    }),

    async readHuntArea () {
      const huntArea = await this.getHuntAreaById(this.hunt.huntAreaId)
      this.huntArea = huntArea.name
    },

    close () {
      this.setHunt(null)
    },

    viewHunt () {
      this.$router.push({ name: 'GeneralTab', params: { huntId: this.hunt.id } })
    }
  }
}
</script>
