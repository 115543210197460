<template>
  <Dialog
    :title="title"
    :closable="true"
    :close-enabled="!isSubmitting"
    :buttons="buttons"
    @close="close">
    <p>{{ messageText }}</p>
  </Dialog>
</template>

<script>
import Dialog from '@/components/ui/dialog2/Dialog2.vue'

export default {
  components: {
    Dialog
  },

  props: {
    answer: {
      type: String,
      default: 'accept'
    },

    invite: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      status: ''
    }
  },

  computed: {
    isSubmitting () {
      return this.status === 'submitting'
    },

    isAccepting () {
      return this.answer === 'accept'
    },

    buttons () {
      return [{
        title: this.$t('general.cancel'),
        disabled: this.isSubmitting,
        click: () => this.close()
      }, {
        title: this.submitButtonTitle,
        type: 'primary',
        loading: this.isSubmitting,
        click: this.submit
      }]
    },

    title () {
      return this.isAccepting
        ? this.$i18n.t('hunt.invite.acceptInvite')
        : this.$i18n.t('hunt.invite.declineInvite')
    },

    submitButtonTitle () {
      return this.isAccepting
        ? this.$i18n.t('hunt.invite.accept')
        : this.$i18n.t('hunt.invite.decline')
    },

    messageText () {
      const stringId = this.isAccepting
        ? 'hunt.invite.acceptConfirmMessage'
        : 'hunt.invite.declineConfirmMessage'

      return this.$i18n.t(stringId, {
        huntArea: this.invite.name,
        date: this.$dayjs(this.invite.startsAt).format('D MMM YYYY')
      })
    }
  },

  methods: {
    async submit () {
      this.status = 'submitting'

      try {
        await this.$store.dispatch('hunt/invites/answerInvite', {
          invite: this.invite,
          answer: this.isAccepting
        })

        this.close(this.answer)
      } catch (error) {
        this.$notification.danger(this.$i18n.t('hunt.invite.respondToInviteFailedErrorMessage'))
        console.error(error)
        this.close()
      }
    },

    close (response) {
      this.$emit('close', response)
    }
  }
}
</script>
