<template>
  <CalendarSidebarSection
    :title="$t('calendar.invites')"
    :loading="isLoading"
    :expanded="isExpandedByDefault"
    :expandable="true"
    :item-count="itemCount">
    <div v-if="!isLoading && invites.length > 0">
      <CalendarSidebarInvitesSectionItem
        v-for="i in invites"
        :key="i.id"
        :invite="i"
        @accept="answerInvite(i, 'accept')"
        @decline="answerInvite(i, 'decline')">
      </CalendarSidebarInvitesSectionItem>
    </div>

    <Paragraph v-else class="has-text-grey" :text="$t('calendar.noInvites')" />

    <AnswerHuntInviteDialog
      v-if="answer"
      :invite="invite"
      :answer="answer"
      @close="closeAnswerInviteModal">
    </AnswerHuntInviteDialog>
  </CalendarSidebarSection>
</template>

<script>
import { mapGetters } from 'vuex'
import CalendarSidebarSection from './CalendarSidebarSection.vue'
import CalendarSidebarInvitesSectionItem from './CalendarSidebarInvitesSectionItem.vue'

import AnswerHuntInviteDialog from '@/components/dialogs/AnswerHuntInviteDialog.vue'

export default {
  components: {
    CalendarSidebarSection,
    CalendarSidebarInvitesSectionItem,
    AnswerHuntInviteDialog
  },

  data () {
    return {
      answer: null,
      invite: null
    }
  },

  computed: {
    ...mapGetters({
      invites: 'hunt/invites/invites'
    }),

    isLoading () {
      return this.invites === null
    },

    itemCount () {
      return this.invites !== null ? this.invites.length : 0
    },

    isExpandedByDefault () {
      return !this.$mq.phone
    }
  },

  methods: {
    answerInvite (invite, answer) {
      this.invite = invite
      this.answer = answer
    },

    closeAnswerInviteModal (response) {
      if (response === 'accept') {
        this.$store.dispatch('calendar/updateHuntFromInvite', this.invite)
      } else if (response === 'decline') {
        this.$store.commit('calendar/removeHunt', this.invite.id)
      }
      this.answer = null
    }
  }
}
</script>

<style scoped>
.accordion-title {
  margin-bottom: 0 !important;
}
</style>
