import api from '@/api/'
import TimeZone from '@/assets/libs/timeZone'
import CalendarEventInviteModel from '../models/calendarEventInviteModel'

class CalendarEventInviteService {
  static async invite (huntAreaId, eventId, userId, language) {
    try {
      const request = {
        userId: userId,
        language: language,
        timeZone: TimeZone.getCurrent()
      }

      await api.post(`/areas/${huntAreaId}/calendar/events/${eventId}/members`, request)

      // I have to fetch the newly invited user in order to return the object, kind of weird :P
      // I would rather have the API return the object for me instead of doing this
      const { data } = await api.get(`/areas/${huntAreaId}/calendar/events/${eventId}/members`)
      const invite = data.filter(invite => invite.invitedUserId === userId)[0]

      return new CalendarEventInviteModel(invite)
    } catch (error) {
      console.error(`Failed to invite user ${userId} to event ${eventId}.`)
      throw error
    }
  }

  static async delete (huntAreaId, eventId, userId) {
    try {
      await api.delete(`/areas/${huntAreaId}/calendar/events/${eventId}/members/${userId}`)
    } catch (error) {
      console.error(`Failed to delete calendar event invite for user ${userId}`)
      throw error
    }
  }

  static async read (huntAreaId, eventId) {
    try {
      const { data } = await api.get(`/areas/${huntAreaId}/calendar/events/${eventId}/members`)
      const invites = data.map(invite => new CalendarEventInviteModel(invite))
      return invites
    } catch (error) {
      console.error(`Failed to read invites for event ${eventId}.`)
      throw error
    }
  }
}

export default CalendarEventInviteService
