<template>
  <Dialog
    :title="$t('calendar.planHunt')"
    :buttons="buttons"
    :closeEnabled="!submitting"
    @close="close">
    <p>{{ $t('calendar.planHuntHelpText') }}</p>

    <div class="select">
      <select @change="onHuntAreaChanged($event)">
        <option
          v-for="huntArea in huntAreas"
          :key="huntArea.id"
          :value="huntArea.id">
          {{ huntArea.name }}
        </option>
      </select>
    </div>
  </Dialog>
</template>

<script>
import Dialog from '@/components/ui/dialog2/Dialog2.vue'

export default {
  components: {
    Dialog
  },

  data () {
    return {
      huntAreaId: null,
      submitting: false
    }
  },

  computed: {
    buttons () {
      return [{
        title: this.$i18n.t('general.cancel'),
        disabled: this.submitting,
        click: this.close
      }, {
        title: this.$i18n.t('calendar.planHunt'),
        type: 'primary',
        loading: this.submitting,
        disabled: this.huntAreaId === null,
        click: this.submit
      }]
    },

    huntAreas () {
      const huntAreas = this.$store.getters['huntarea/areas']
      if (huntAreas !== null) {
        return huntAreas.slice(0).sort((a, b) => a.name.localeCompare(b.name))
      }
      return null
    },

    selectedHuntArea () {
      const i = this.huntAreas.findIndex(huntArea => huntArea.id === this.huntAreaId)
      return i !== -1 ? this.huntAreas[i] : null
    }
  },

  created () {
    if (this.huntAreas && this.huntAreas.length > 0) {
      this.huntAreaId = this.huntAreas[0].id
    }
  },

  methods: {
    onHuntAreaChanged (event) {
      this.huntAreaId = event.target.value
    },

    async submit () {
      this.submitting = true

      try {
        const hunt = await this.$store.dispatch('hunt/create', {
          name: this.selectedHuntArea.name,
          huntAreaId: this.selectedHuntArea.id
        })

        this.$router.push({ name: 'GeneralTab', params: { huntId: hunt.id } })
      } catch (error) {
        this.$notification.danger(this.$i18n.t('calendar.planHuntFailedErrorMessage'))
        console.error(error)
      }

      this.submitting = false
    },

    close () {
      this.$emit('close')
    }
  }
}
</script>
