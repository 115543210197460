<template>
  <div class="calendar-dialog-header">
    <div class="columns">
      <div class="column has-text-centered">
        <Label :label="$t('calendar.where')" />
        <div>
          <span v-if="huntArea !== null">{{ huntArea }}</span>
          <InlineLoader v-else />
        </div>
      </div>

      <div class="column has-text-centered">
        <Label :label="startsAtLabel" />
        <div>{{ startsAtFormatted }}</div>
      </div>

      <div class="column has-text-centered">
        <Label :label="endsAtLabel" />
        <div>{{ endsAtFormatted }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    huntArea: {
      type: String,
      default: ''
    },

    startsAt: {
      type: Date,
      default: null
    },

    endsAt: {
      type: Date,
      default: null
    }
  },

  computed: {
    isInFuture () {
      return this.startsAt > new Date()
    },

    startsAtLabel () {
      return this.isInFuture ? this.$i18n.t('calendar.startsAt') : this.$i18n.t('calendar.startedAt')
    },

    endsAtLabel () {
      return this.isInFuture ? this.$i18n.t('calendar.endsAt') : this.$i18n.t('calendar.endedAt')
    },

    startsAtFormatted () {
      return this.$dayjs(this.startsAt).format('D MMM HH:mm')
    },

    endsAtFormatted () {
      return this.$dayjs(this.endsAt).format('D MMM HH:mm')
    }
  }
}
</script>

<style scoped>
.calendar-dialog-header {
  border-top: 1px solid whitesmoke;
  border-bottom: 1px solid whitesmoke;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 1.5rem;
}
</style>
