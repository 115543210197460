<template>
  <div v-if="members !== null" class="members">
    <div v-for="member in members" :key="member.id">
      <div class="level is-mobile">
        <div class="level-left">
          <ProfilePicture
            :userId="Number(member.id)"
            :name="member.getFullName()"
            size="small"
            round>
          </ProfilePicture>

          <span class="push-left-xs">{{ member.getFullName() }}</span>
        </div>

        <div class="level-right">
          <Checkbox v-model="member.selected" />
        </div>
      </div>
    </div>
  </div>

  <div v-else>
    <ContentPlaceholders
      v-for="i in 5"
      :key="i"
      :rounded="true"
      class="push-bottom-xs">
      <ContentPlaceholdersHeading :img="true" />
    </ContentPlaceholders>
  </div>
</template>

<script>
export default {
  props: {
    members: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style scoped>
.members {
  max-height: 420px;
  overflow-y: auto;
}

.members .level {
  margin-bottom: 1rem;
}
</style>
