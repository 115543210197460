<template>
  <Dialog :title="title" :buttons="buttons" @close="close">
    <CalendarDialogHeader
      :hunt-area="huntArea"
      :starts-at="report.startsAt"
      :ends-at="report.endsAt">
    </CalendarDialogHeader>

    <CalendarDialogCommentSection
      :comment="report.comment"
      :is-html="false"
      :empty-text="$t('report.commentNone')">
    </CalendarDialogCommentSection>

    <CalendarReportDialogEventSection :events="report.events" />
    <CalendarReportDialogParticipantSection :participants="report.members" />
  </Dialog>
</template>

<script>
import { mapActions } from 'vuex'
import Dialog from '@/components/ui/dialog2/Dialog2.vue'

import CalendarDialogHeader from '../dialog/CalendarDialogHeader.vue'
import CalendarDialogCommentSection from '../dialog/CalendarDialogCommentSection.vue'
import CalendarReportDialogEventSection from './CalendarReportDialogEventSection.vue'
import CalendarReportDialogParticipantSection from './CalendarReportDialogParticipantSection.vue'

export default {
  components: {
    Dialog,
    CalendarDialogHeader,
    CalendarDialogCommentSection,
    CalendarReportDialogEventSection,
    CalendarReportDialogParticipantSection
  },

  props: {
    report: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      huntArea: null
    }
  },

  computed: {
    buttons () {
      return [{
        type: 'primary',
        title: this.$i18n.t('calendar.seeCompleteReport'),
        click: this.viewReport
      }]
    },

    title () {
      return this.report.title || this.$i18n.t('report.huntingReportDefaultTitle', { date: this.$dayjs(this.startsAt).format('D MMMM') })
    },

    isMember () {
      return this.$store.getters['huntarea/isMember'](this.report.huntAreaId)
    }
  },

  created () {
    this.readHuntArea()
  },

  methods: {
    ...mapActions({
      getHuntAreaById: 'huntarea/getById'
    }),

    async readHuntArea () {
      const huntArea = await this.getHuntAreaById(this.report.huntAreaId)
      this.huntArea = huntArea.name
    },

    close () {
      this.$emit('close')
    },

    viewReport () {
      if (this.isMember) {
        this.viewReportAsMember()
      } else {
        this.viewReportAsGuest()
      }
    },

    viewReportAsMember () {
      this.$router.push({
        name: 'view-report-general-tab',
        params: {
          huntAreaId: this.report.huntAreaId,
          reportId: this.report.id
        },
        query: { from: '/calendar' }
      })
    },

    viewReportAsGuest () {
      this.$router.push({
        name: 'view-report-no-huntarea-general-tab',
        params: { reportId: this.report.id },
        query: {
          huntId: this.report.huntId,
          from: '/calendar'
        }
      })
    }
  }
}
</script>
