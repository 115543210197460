<template>
  <Table bordered scrollable>
    <TableHeader>
      <TableHeaderCell :heading="$t('general.type')" />
      <TableHeaderCell :heading="$t('hunt.gameTitle')" />
      <TableHeaderCell :heading="$t('general.when')" />
      <TableHeaderCell :heading="$t('general.where')" />
      <TableHeaderCell :heading="$t('general.comment')" />
    </TableHeader>

    <TableContent>
      <slot />
    </TableContent>
  </Table>
</template>

<script>
export default {
}
</script>
