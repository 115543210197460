<template>
  <CalendarDialogSection :title="$t('hunt.events.title')">
    <div v-if="events !== null">
      <Table v-if="events.length > 0" bordered scrollable>
        <TableHeader>
          <TableHeaderCell :heading="$t('general.type')" />
          <TableHeaderCell :heading="$t('hunt.gameTitle')" />
          <TableHeaderCell :heading="$t('general.when')" />
          <TableHeaderCell :heading="$t('general.comment')" />
        </TableHeader>

        <TableContent>
          <TableRow v-for="event in events" :key="event.id">
            <TableCell class="is-narrow">
              <img :src="icon(event)" />
            </TableCell>

            <TableCell class="is-narrow">
              {{ event.name }}
            </TableCell>

            <TableCell class="is-narrow">
              {{ date(event.createdAt) }}
            </TableCell>

            <TableCell class="has-text-grey">
              <small>{{ event.note }}</small>
            </TableCell>
          </TableRow>
        </TableContent>
      </Table>

      <p v-else class="has-text-grey">
        {{ $t('hunt.events.noEvents') }}
      </p>
    </div>

    <InlineLoader v-else />
  </CalendarDialogSection>
</template>

<script>
import { mapGetters } from 'vuex'
import CalendarDialogSection from '../dialog/CalendarDialogSection.vue'
import { getMarkerTypeIconUrl } from '@/api/map/markers/markers'

export default {
  components: {
    CalendarDialogSection
  },

  computed: {
    ...mapGetters({
      events: 'calendar/selectedHunt/getEvents'
    })
  },

  methods: {
    icon (event) {
      return getMarkerTypeIconUrl(event.type)
    },

    date (date) {
      return this.$dayjs(date).format('HH:mm')
      // return this.hunt.isMultipleDays() ? this.$dayjs(date).format('D MMM HH:mm') : this.$dayjs(date).format('HH:mm')
    }
  }
}
</script>
