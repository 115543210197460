<template>
  <span class="moon-phase">
    <div
      :class="[phase]"
      :title="title"
      class="moon">
    </div>
  </span>
</template>

<script>
import dayjs from 'dayjs'
import Moon from '@/assets/libs/moonPhase/'

export default {
  props: {
    date: {
      type: Date,
      default: null
    }
  },

  data () {
    return {
      phase: ''
    }
  },

  computed: {
    day () {
      return Number(dayjs(this.date).format('DD'))
    },

    month () {
      return Number(dayjs(this.date).format('MM'))
    },

    year () {
      return Number(dayjs(this.date).format('YYYY'))
    },

    title () {
      return this.phase ? this.$i18n.t(`calendar.moonPhases.${this.phase}`) : ''
    }
  },

  mounted () {
    this.phase = Moon.phase(this.year, this.month, this.day)
  }
}
</script>

<style lang="scss">
.moon-phase {
  position: absolute;
  top: 0;
  right: 0;
  margin: 1px;

  .moon {
    position: relative;
    width: 0.85rem;
    height: 0.85rem;
    border-radius: 50%;
    background: #dedede;
    margin: 0.25rem;
    box-shadow: 0 0px 0px 1px rgb(248, 248, 248);

    &::after {
      content: "";
      display: block;
      height: 100%;
      width: 100%;
      border-radius: 50%;
      background: #eabd34;
      opacity: 0.75;
    }

    &.new-moon::after {
      background: #dedede;
    }

    &.waxing-crescent-moon::after {
      clip-path: ellipse(75% 75% at 0% 50%);
    }

    &.first-quarter-moon::after {
      clip-path: ellipse(55% 100% at 0% 50%);
    }

    &.waxing-gibbous-moon::after {
      clip-path: ellipse(75% 75% at 100% 50%);
    }

    &.full-moon::after {
      background: #eabd34;
    }

    &.waning-gibbous-moon::after {
      clip-path: ellipse(75% 75% at 0% 50%);
    }

    &.last-quarter-moon::after {
      clip-path: ellipse(55% 100% at 100% 50%);
    }

    &.waning-crescent-moon::after {
      clip-path: ellipse(75% 75% at 100% 50%);
    }
  }
}
</style>
