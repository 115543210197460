<template>
  <div v-if="event !== null">
    <textarea
      v-if="edit"
      v-model="event.description"
      :placeholder="$t('calendar.activity.descriptionPlaceholder')"
      class="textarea is-not-resizeble"
      rows="6"></textarea>

    <p v-else class="description">{{ event.description }}</p>
  </div>

  <ContentPlaceholders v-else>
    <ContentPlaceholdersText :lines="4" />
  </ContentPlaceholders>
</template>

<script>
export default {
  props: {
    event: {
      type: Object,
      default: () => {}
    },

    edit: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.description {
  white-space: pre-wrap;
}
</style>
