<template>
  <div class="calendar-dialog-section">
    <Label v-if="title.length > 0" :label="title" />

    <InlineLoader v-if="loading" />
    <slot v-else />
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },

    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
  .calendar-dialog-section {
    margin-bottom: 1.5rem;
  }

  .calendar-dialog-section:last-child {
    margin-bottom: 0;
  }
</style>
