<template>
  <Wrapper class="subpage page-calendar">
    <SplitView>
      <CalendarSidebar slot="sidebar" class="default-spacing" />

      <CalendarView
        v-if="events"
        slot="wrapper"
        :locale="languageCode"
        :events="events"
        :startingDayOfWeek="1"
        :show-date="showDate"
        :period-changed-callback="periodChanged"
        eventContentHeight="2rem"
        eventTop="1.75rem"
        class="theme-default"
        @click-event="eventClick"
        @click-date="dateClick">
        <CalendarTitlebar
          slot="header"
          slot-scope="t"
          :isLoading="loading"
          :header-props="t.headerProps"
          @input="setShowDate"
          @new-activity="newActivity"
          @plan-hunt="planHunt">
        </CalendarTitlebar>

        <div slot="dayContent" slot-scope="{ day }">
          <MoonPhase :date="day" />
        </div>
      </CalendarView>
    </SplitView>

    <CalendarEventDialog
      v-if="selectedEvent !== null"
      :selectedEvent="selectedEvent"
      @close="selectedEvent = null">
    </CalendarEventDialog>

    <CalendarReportDialog
      v-if="selectedReport !== null"
      :report="selectedReport"
      @close="selectedReport = null">
    </CalendarReportDialog>

    <CalendarHuntDialog v-if="selectedHunt !== null" />

    <CalendarPlanHuntDialog
      v-if="showPlanHuntModal"
      @close="showPlanHuntModal = false">
    </CalendarPlanHuntDialog>
  </Wrapper>
</template>

<style src="./calendar.scss" lang="scss"></style>

<script>
import dayjs from 'dayjs'
import { mapGetters } from 'vuex'
import { CalendarView } from 'vue-simple-calendar'

import CalendarSidebar from './sidebar/CalendarSidebar.vue'
import CalendarTitlebar from './CalendarTitlebar.vue'
import CalendarEventDialog from './event/CalendarEventDialog.vue'
import CalendarReportDialog from './report/CalendarReportDialog.vue'
import CalendarHuntDialog from './hunt/CalendarHuntDialog.vue'
import CalendarPlanHuntDialog from './hunt/CalendarPlanHuntDialog.vue'

import MoonPhase from './MoonPhase.vue'

import CalendarEventModel from '../models/calendarEventModel'
import { HUNT_STATUS_FINISHED } from '../../../api/hunt/huntModel'
import DOMPurify from "dompurify"

export default {
  components: {
    CalendarSidebar,
    CalendarView,
    CalendarTitlebar,
    CalendarEventDialog,
    CalendarReportDialog,
    CalendarHuntDialog,
    CalendarPlanHuntDialog,
    MoonPhase
  },

  data () {
    return {
      showDate: new Date(),
      selectedEvent: null,
      selectedReport: null,
      showPlanHuntModal: false,
      loading: false,
      updateTimer: null
    }
  },

  computed: {
    ...mapGetters({
      isLoading: 'calendar/isLoading',
      selectedHunt: 'calendar/selectedHunt/getHunt'
    }),

    languageCode () {
      return this.$store.getters['ui/language']
    },

    huntAreas () {
      const huntAreas = this.$store.getters['huntarea/areas']
      return huntAreas !== null ? huntAreas : []
    },

    events () {
      let events = this.$store.getters['calendar/getEvents'] || []
      let hunts = this.$store.getters['calendar/getHunts'] || []
      let reports = this.$store.getters['calendar/getReports'] || []

      events = events.map(event => this.createCalendarEvent(event))
      hunts = hunts.map(hunt => this.createCalendarHuntEvent(hunt))
      reports = reports.map(report => this.createCalendarReportEvent(report))

      return this.filterEvents([...events, ...hunts, ...reports])
    }
  },

  watch: {
    huntAreas (huntAreas) {
      this.update()

      if (huntAreas && this.$route.query.huntAreaId) {
        this.selectNavigatedHuntArea()
      }
    }
  },

  created () {
    this.$store.commit('calendar/clear')
  },

  mounted () {
    if (this.huntAreas && this.$route.query.huntAreaId) {
      this.selectNavigatedHuntArea()
    }
  },

  destroyed () {
    this.$store.commit('calendar/selectedHunt/setHunt', null)
  },

  methods: {
    async update () {
      if (this.$store.getters['huntarea/areas'] !== null) {
        if (this.updateTimer) {
          clearTimeout(this.updateTimer)
          this.updateTimer = null
        }

        this.updateTimer = setTimeout(async () => {
          try {
            await this.$store.dispatch('calendar/update')
          } catch (error) {
            this.$notification.danger(this.$i18n.t('calendar.loadActivitiesFailedErrorMessage'))
          }
        }, 1000)
      }
    },

    createCalendarEvent (event) {
      return {
        id: event.id,
        huntAreaId: event.huntAreaId,
        type: 'event',
        title: DOMPurify.sanitize(this.getEventTitle(event)),
        startDate: event.from,
        endDate: event.to,
        description: event.description,
        classes: event.status === 'member' ? 'is-event invited' : 'is-event not-invited'
      }
    },

    createCalendarHuntEvent (hunt) {
      return {
        id: hunt.id,
        huntAreaId: hunt.huntAreaId,
        type: 'hunt',
        title: DOMPurify.sanitize(hunt.name),
        startDate: hunt.startsAt,
        endDate: hunt.endsAt,
        description: hunt.note,
        classes: hunt.memberStatus === 'member' ? 'is-hunt member' : 'is-hunt not-invited'
      }
    },

    createCalendarReportEvent (report) {
      const title = report.title || this.$i18n.t('report.huntingReportDefaultTitle', { date: this.$dayjs(report.startsAt).format('D MMMM') })

      return {
        id: report.id,
        huntAreaId: report.huntAreaId,
        type: 'report',
        title: DOMPurify.sanitize(title),
        startDate: report.startsAt,
        endDate: report.endsAt,
        description: report.comment,
        classes: 'is-hunt member',
        report: report
      }
    },

    filterEvents (events) {
      const selectedHuntAreas = this.huntAreas.filter(huntArea => huntArea.selected)

      return events.filter(event => {
        const isGuestHunt = this.huntAreas.findIndex(huntArea => huntArea.id === event.huntAreaId) === -1
        if (isGuestHunt) {
          return true // Guest hunts are always visible for now
        }

        const i = selectedHuntAreas.findIndex(huntArea => huntArea.id === event.huntAreaId)
        return i !== -1
      })
    },

    getEventTitle (event) {
      const i = this.huntAreas.findIndex(huntArea => huntArea.id === event.huntAreaId)
      return i !== -1 ? this.huntAreas[i].name + ': ' + event.title : event.title
    },

    periodChanged (range) {
      this.$store.commit('calendar/setPeriod', {
        from: range.periodStart,
        to: range.periodEnd
      })

      this.update()
    },

    setShowDate (d) {
      this.showDate = d
    },

    eventClick (evt) {
      const event = evt.originalEvent

      switch (event.type) {
        case 'hunt':
          this.selectHunt(event)
          break

        case 'event':
          this.showEvent(event)
          break

        case 'report':
          this.showReport(event)
          break
      }
    },

    async selectHunt (event) {
      const hunt = this.$store.getters['calendar/getHunt'](event.id)

      if (hunt.status === HUNT_STATUS_FINISHED) {
        // Finished hunts are never visible for members on the hunting ground, so we can assume the user is a guest
        // and the report needs to be read from HC instead of Firebase.
        try {
          const report = await this.$store.dispatch('huntarea/reports/getByHunt', hunt)
          this.selectedReport = report
        } catch (error) {
          // In case loading the report fails, fallback to showing the hunt
          this.$store.dispatch('calendar/selectedHunt/select', hunt)
        }
      } else {
        this.$store.dispatch('calendar/selectedHunt/select', hunt)
      }
    },

    showEvent (event) {
      this.selectedEvent = event
    },

    showReport (event) {
      this.selectedReport = event.report
    },

    dateClick (event) {
      if (this.huntAreas.length > 0) {
        if (event.constructor === Date && dayjs().subtract(1, 'day').isBefore(dayjs(event))) {
          this.newActivity(event)
        }
      }
    },

    newActivity (date) {
      this.selectedEvent = new CalendarEventModel()
      this.selectedEvent.from = date
    },

    planHunt () {
      this.showPlanHuntModal = true
    },

    selectNavigatedHuntArea () {
      if (this.huntAreas && this.huntAreas.length && this.$route.query.huntAreaId) {
        this.huntAreas.forEach(huntArea => {
          huntArea.selected = false
        })

        const navigatedHuntArea = this.huntAreas.filter(huntArea => huntArea.id === this.$route.query.huntAreaId)[0]
        navigatedHuntArea.selected = true
      }
    }
  }
}
</script>
