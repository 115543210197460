// Original Snippet: https://gist.github.com/endel/dfe6bb2fbe679781948c
// Moon.phase(2018, 01, 19)

export default {
  phases: ['newMoon', 'waxingCrescentMoon', 'firstQuarterMoon', 'waxingGibbousMoon', 'fullMoon', 'waningGibbousMoon', 'lastQuarterMoon', 'waningCrescentMoon'],

  phase (year, month, day) {
    var c = 0
    var e = 0
    var jd = 0
    var b = 0

    if (month < 3) {
      year--
      month += 12
    }

    ++month
    c = 365.25 * year
    e = 30.6 * month
    jd = c + e + day - 694039.09 // jd is total days elapsed
    jd /= 29.5305882 // divide by the moon cycle
    b = parseInt(jd) // int(jd) -> b, take integer part of jd
    jd -= b // subtract integer part to leave fractional part of original jd
    b = Math.round(jd * 8) // scale fraction from 0-8 and round

    if (b >= 8) b = 0 // 0 and 8 are the same so turn 8 into 0

    return this.phases[b]
  }
}
