<template>
  <div class="level is-mobile">
    <div class="level-left">
      <div class="level-item">
        <ProfilePicture
          size="small"
          :user-id="Number(userId)"
          round>
        </ProfilePicture>
        <span class="push-left-xs">{{ name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    userId: {
      type: String,
      default: null
    },

    name: {
      type: String,
      default: ''
    },

    role: {
      type: String,
      default: null
    }
  }
}
</script>
