<template>
  <div v-if="event !== null">
    <div v-if="edit">
      <FlatPickr
        v-if="event !== null"
        v-model="val"
        :config="dateConfig"
        class="input"
        @on-change="onChange" />

      <p v-if="error" class="help is-danger">{{ errorMessage }}</p>
    </div>

    <span v-else>{{ date }}</span>
  </div>

  <ContentPlaceholders v-else>
    <ContentPlaceholdersText :lines="1" />
  </ContentPlaceholders>
</template>

<script>
import dayjs from 'dayjs'

export default {
  props: {
    value: {
      type: Date,
      default: null
    },

    event: {
      type: Object,
      default: () => {}
    },

    error: {
      type: Boolean,
      default: false
    },

    errorMessage: {
      type: String,
      default: ''
    },

    edit: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      val: this.value,
      dateConfig: {
        enableTime: true,
        time_24hr: true,
        dateFormat: 'Y-m-d H:i'
      }
    }
  },

  computed: {
    date () {
      return this.val !== null ? dayjs(this.val).format('YYYY-MM-DD HH:mm') : ''
    }
  },

  methods: {
    onChange (values) {
      this.$emit('input', values[0])
    }
  }
}
</script>
