<template>
  <CalendarDialogSection :title="$t('hunt.assignments.title')">
    <InlineLoader v-if="isLoading" />

    <div v-else>
      <div v-if="drives.length > 0">
        <CalendarHuntDialogAssignmentSectionTable
          v-for="drive in drives"
          :key="drive.id"
          :drive="drive">
        </CalendarHuntDialogAssignmentSectionTable>
      </div>

      <p v-else class="has-text-grey">
        {{ $t('hunt.assignments.noAssignments') }}
      </p>
    </div>
  </CalendarDialogSection>
</template>

<script>
import { mapGetters } from 'vuex'

import CalendarDialogSection from '../dialog/CalendarDialogSection.vue'
import CalendarHuntDialogAssignmentSectionTable from './CalendarHuntDialogAssignmentSectionTable.vue'

export default {
  components: {
    CalendarDialogSection,
    CalendarHuntDialogAssignmentSectionTable
  },

  computed: {
    ...mapGetters({
      drives: 'calendar/selectedHunt/getDrives',
      subAreas: 'calendar/selectedHunt/getSubAreas',
      markers: 'calendar/selectedHunt/getMarkers'
    }),

    isLoading () {
      return this.drives === null || this.subAreas === null || this.markers === null
    }
  }
}
</script>
