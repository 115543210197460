<template>
  <div class="calendar-sidebar-invites-section-item">
    <p class="hunt-area-name">{{ invite.name }}</p>
    <p>{{ date() }}</p>

    <ButtonGroup size="small" class="push-top">
      <Button
        type="success"
        icon="icon-check"
        :title="$t('hunt.invite.accept')"
        outlined
        @click="accept" />

      <Button
        type="danger"
        icon="icon-x"
        :title="$t('hunt.invite.decline')"
        outlined
        @click="decline" />
    </ButtonGroup>
  </div>
</template>

<script>
export default {
  props: {
    invite: {
      type: Object,
      default: () => {}
    }
  },

  methods: {
    date () {
      const isSameDay = this.$dayjs(this.invite.startsAt).isSame(this.invite.endsAt, 'day')
      const isSameMonth = this.$dayjs(this.invite.startsAt).isSame(this.invite.endsAt, 'month')

      const startTime = this.formatTime(this.invite.startsAt)
      const endTime = this.formatTime(this.invite.endsAt)

      let date = ''

      if (isSameDay) {
        date = this.$dayjs(this.invite.startsAt).format('D MMMM')
      } else if (isSameMonth) {
        const startDate = this.$dayjs(this.invite.startsAt).format('D')
        const endDate = this.$dayjs(this.invite.endsAt).format('D MMMM')

        date = `${startDate} - ${endDate}`
      } else {
        const startDate = this.$dayjs(this.invite.startsAt).format('D MMMM')
        const endDate = this.$dayjs(this.invite.endsAt).format('D MMMM')

        date = `${startDate} - ${endDate}`
      }

      return `${date}, ${startTime} - ${endTime}`
    },

    formatTime (date) {
      return date.getMinutes() > 0 ? this.$dayjs(date).format('H:mm') : this.$dayjs(date).format('H')
    },

    accept () {
      this.$emit('accept')
    },

    decline (invite) {
      this.$emit('decline')
    }
  }
}
</script>

<style lang="scss">
.calendar-sidebar-invites-section-item {
  width: 100%;
  overflow: hidden;
  border: 1px solid rgb(230, 230, 230);
  border-radius: 4px;
  padding: 1.25rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  margin-bottom: 1rem;

  p {
    margin-bottom: 0 !important;
  }

  .hunt-area-name {
    font-size: 1rem;
    font-weight: 600;
  }

  .buttons {
    margin-bottom: 0 !important;
  }

  .buttons .button {
    margin-bottom: 0 !important;
  }
}
</style>
