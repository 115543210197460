<template>
  <div class="calendar-hunt-dialog-drive">
    <Heading size="6" :text="driveName" />

    <Table fullwidth hoverable bordered scrollable>
      <TableHeader>
        <TableHeaderCell :heading="$t('general.name')" />
        <TableHeaderCell :heading="$t('general.role')" />
        <TableHeaderCell :heading="$t('hunt.assignments.stand')" />
      </TableHeader>

      <TableContent>
        <CalendarHuntDialogAssignmentSectionTableRow
          v-for="assignment in assignments"
          :key="assignment.id"
          :assignment="assignment">
        </CalendarHuntDialogAssignmentSectionTableRow>
      </TableContent>
    </Table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CalendarHuntDialogAssignmentSectionTableRow from './CalendarHuntDialogAssignmentSectionTableRow.vue'

export default {
  components: {
    CalendarHuntDialogAssignmentSectionTableRow
  },

  props: {
    drive: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters({
      subAreas: 'calendar/selectedHunt/getSubAreas'
    }),

    subArea () {
      if (this.subAreas !== null) {
        const i = this.subAreas.findIndex(subArea => subArea.id === this.drive.subAreaId)
        return i !== -1 ? this.subAreas[i] : null
      }
      return null
    },

    driveName () {
      if (this.drive.subAreaId !== null) {
        return this.subArea !== null ? this.subArea.name : ''
      }
      return this.$i18n.t('hunt.assignments.entireHuntArea')
    },

    assignments () {
      return this.drive.assignments.slice(0).sort((a, b) => a.getFullName().localeCompare(b.getFullName()))
    }
  }
}
</script>

<style stoped>
.calendar-hunt-dialog-drive {
  margin-bottom: 1rem;
}

.calendar-hunt-dialog-drive:last-child {
  margin-bottom: 0;
}
</style>
