<template>
  <div>
    <div v-if="comment !== null">
      <p v-if="comment.length > 0 && isHtml" v-html="comment" />
      <p v-else-if="comment.length > 0 && !isHtml" class="comment-is-text">{{ comment }}</p>
      <p v-else-if="emptyText !== null" class="has-text-grey">
        {{ emptyText }}
      </p>
    </div>

    <InlineLoader v-else />
    <Divider />
  </div>
</template>

<script>
export default {
  props: {
    comment: {
      type: String,
      default: null
    },

    emptyText: {
      type: String,
      default: null
    },

    isHtml: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped>
.comment-is-text {
  white-space: pre-wrap;
}
</style>
