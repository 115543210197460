<template>
  <div v-if="!loading">
    <Dropdown
      v-if="edit && event.id === null"
      :text="selected !== null ? selected.name : ''">
      <DropdownItem
        v-for="huntArea in huntAreas"
        :key="huntArea.id"
        @click="selectHuntArea(huntArea)">
        {{ huntArea.name }}
      </DropdownItem>
    </Dropdown>

    <span v-else>{{ selected !== null ? selected.name : '' }}</span>
  </div>

  <ContentPlaceholders v-else>
    <ContentPlaceholdersText :lines="1" />
  </ContentPlaceholders>
</template>

<script>
export default {
  props: {
    selected: {
      type: Object,
      default: () => {}
    },

    event: {
      type: Object,
      default: () => {}
    },

    edit: {
      type: Boolean,
      default: false
    },

    loading: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    huntAreas () {
      const huntAreas = this.$store.getters['huntarea/areas']
      if (huntAreas !== null) {
        return huntAreas.slice(0).sort((a, b) => a.name.localeCompare(b.name))
      }
      return null
    }
  },

  methods: {
    selectHuntArea (huntArea) {
      this.$emit('huntarea-selected', huntArea)
    }
  }
}
</script>
