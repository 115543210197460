<template>
  <div class="calendar-sidebar-huntarea-section-item" @click="toggleSelect">
    <div class="level is-mobile">
      <div class="level-left">
        <div class="level-item">
          <Icon :name="eye" />
        </div>

        <div class="level-item huntarea-name">
          {{ huntArea.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    huntArea: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    eye () {
      return this.huntArea.selected ? 'icon-eye' : 'icon-eye-off'
    }
  },

  methods: {
    toggleSelect () {
      this.huntArea.selected = !this.huntArea.selected
      this.$emit('selected', this.huntArea)
    }
  }
}
</script>

<style scoped>
.calendar-sidebar-huntarea-section-item {
  cursor: pointer;
  padding: 0.25rem 0 0.25rem 0;
  background-color: white;
}

.calendar-sidebar-huntarea-section-item:hover {
  background-color: rgb(245, 245, 245);
}
</style>
