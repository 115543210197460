<template>
  <div class="calendar-sidebar-section">
    <div class="level is-mobile" :class="{ 'is-clickable': expandable && !loading}" @click="toggleExpanded">
      <div class="level-left">
        <div class="level-item">
          <Heading size="5" class="calendar-sidebar-section-title" :text="title" />
        </div>

        <div class="level-item">
          <Badge v-if="itemCount > 0" :text="itemCount.toString()" />
        </div>
      </div>

      <div class="level-right">
        <div class="level-item">
          <InlineLoader v-if="loading" />
          <Icon v-else-if="expandable" :name="chevron" />
        </div>
      </div>
    </div>

    <div v-if="!loading" class="calendar-sidebar-section-body">
      <slot v-if="isExpanded" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },

    itemCount: {
      type: Number,
      default: 0
    },

    loading: {
      type: Boolean,
      default: false
    },

    expandable: {
      type: Boolean,
      default: true
    },

    expanded: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      isExpanded: this.expanded
    }
  },

  computed: {
    chevron () {
      return this.isExpanded ? 'icon-chevron-up' : 'icon-chevron-down'
    }
  },

  methods: {
    toggleExpanded () {
      if (!this.loading && this.expandable) {
        this.isExpanded = !this.isExpanded
      }
    }
  }
}
</script>

<style scoped>
.calendar-sidebar-section:not(:last-child) {
  margin-bottom: 1rem;
}

.calendar-sidebar-section-title {
  margin-bottom: 0 !important;
}

.calendar-sidebar-section .level {
  margin-bottom: 0.5rem !important;
}
</style>
