<template>
  <CalendarDialogSection :title="getTitle">
    <InlineLoader v-if="loading" />
    <slot v-else />
  </CalendarDialogSection>
</template>

<script>
import CalendarDialogSection from '../dialog/CalendarDialogSection.vue'

export default {
  components: {
    CalendarDialogSection
  },

  props: {
    loading: {
      type: Boolean,
      default: false
    },

    title: {
      type: String,
      default: null
    }
  },

  computed: {
    getTitle () {
      return this.title ? this.title : this.$i18n.t('hunt.participants')
    }
  }
}
</script>
