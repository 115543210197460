<template>
  <CalendarDialogSection :title="membersTitle">
    <div v-if="participants !== null">
      <Member
        v-for="participant in participants"
        :key="participant.id"
        :member="participant"
        :removable="false"
        :show-status="!isFinished">
      </Member>
    </div>

    <InlineLoader v-else />
  </CalendarDialogSection>
</template>

<script>
import { mapGetters } from 'vuex'

import CalendarDialogSection from '../dialog/CalendarDialogSection.vue'
import Member from '@/views/hunt/tabs/members/components/Member.vue'

export default {
  components: {
    CalendarDialogSection,
    Member
  },

  computed: {
    ...mapGetters({
      hunt: 'calendar/selectedHunt/getHunt',
      participants: 'calendar/selectedHunt/getParticipants'
    }),

    isFinished () {
      return this.hunt.status === 'finished'
    },

    membersTitle () {
      return this.isFinished ? this.$i18n.t('hunt.participants') : this.$i18n.t('calendar.invited')
    }
  }
}
</script>
