<template>
  <div class="modal is-active">
    <div class="modal-background"></div>

    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          <span v-if="event !== null">{{ title }}</span>
          <span v-else class="placeholder-title">XXXXXXXXX</span>
          <span v-if="createdBy !== null" class="created-by">{{ createdByLabel }}</span>
        </p>

        <button
          class="delete"
          aria-label="close"
          @click="close">
        </button>
      </header>

      <section class="modal-card-body">
        <form @submit="submit">
          <div class="columns">
            <div class="column is-two-thirds">
              <FormField :label="$t('calendar.activity.huntArea')">
                <CalendarEventDialogHuntAreaDropdown
                  :event="event"
                  :selected="selectedHuntArea"
                  :edit="editing"
                  :loading="event === null"
                  @huntarea-selected="selectHuntArea">
                </CalendarEventDialogHuntAreaDropdown>
              </FormField>

              <FormField :label="$t('calendar.activity.title')">
                <CalendarEventDialogTitle
                  :event="event"
                  :edit="editing"
                  :error="errors.title">
                </CalendarEventDialogTitle>
              </FormField>

              <div class="columns">
                <div class="column">
                  <FormField :label="$t('calendar.activity.from')">
                    <CalendarEventDialogDatePicker
                      v-model="event.from"
                      :event="event"
                      :error="errors.from"
                      :error-message="$t('calendar.activity.fromRequired')"
                      :edit="editing">
                    </CalendarEventDialogDatePicker>
                  </FormField>
                </div>

                <div class="column">
                  <FormField :label="$t('calendar.activity.to')">
                    <CalendarEventDialogDatePicker
                      v-model="event.to"
                      :event="event"
                      :error="errors.to"
                      :error-message="$t('calendar.activity.toRequired')"
                      :edit="editing">
                    </CalendarEventDialogDatePicker>
                  </FormField>
                </div>
              </div>

              <FormField :label="$t('calendar.activity.description')">
                <CalendarEventDialogDescription :event="event" :edit="editing" />
              </FormField>
            </div>

            <div class="column">
              <FormField :label="$t('calendar.activity.participants')">
                <CalendarEventDialogHuntAreaMembers v-if="editing" :members="members" />
                <CalendarEventDialogInvites v-else :invites="invites" />
              </FormField>
            </div>
          </div>
        </form>
      </section>

      <footer class="modal-card-foot">
        <div class="level is-mobile">
          <div class="level-left">
            <Button
              v-if="canRemove"
              type="danger"
              :outlined="true"
              :loading="removing"
              icon="icon-trash"
              @click="remove">
            </Button>
          </div>

          <div class="level-right">
            <Button
              v-if="canEdit"
              type="secondary"
              :title="$t('general.edit')"
              @click="editing = !editing">
            </Button>

            <Button
              v-if="editing"
              type="primary"
              :loading="submitting"
              :title="$t('general.save')"
              @click="submit">
            </Button>

            <Button
              v-if="canLeave"
              type="danger"
              :outlined="true"
              :loading="leaving"
              :title="$t('calendar.activity.removeParticipation')"
              @click="onLeave">
            </Button>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import CalendarEventDialogHuntAreaMembers from './CalendarEventDialogHuntAreaMembers.vue'
import CalendarEventDialogInvites from './CalendarEventDialogInvites.vue'
import CalendarEventDialogHuntAreaDropdown from './CalendarEventDialogHuntAreaDropdown.vue'
import CalendarEventDialogTitle from './CalendarEventDialogTitle.vue'
import CalendarEventDialogDescription from './CalendarEventDialogDescription.vue'
import CalendarEventDialogDatePicker from './CalendarEventDialogDatePicker.vue'

import CalendarEventService from '../../services//calendarEventService'
import CalendarEventInviteService from '../../services/calendarEventInviteService'

import CalendarEventModel, { CALENDAR_EVENT_STATUS_MEMBER, CALENDAR_EVENT_STATUS_NOT_INVITED } from '../../models/calendarEventModel'

import dayjs from 'dayjs'

export default {
  components: {
    CalendarEventDialogHuntAreaDropdown,
    CalendarEventDialogTitle,
    CalendarEventDialogDescription,
    CalendarEventDialogDatePicker,
    CalendarEventDialogHuntAreaMembers,
    CalendarEventDialogInvites
  },

  props: {
    selectedEvent: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      submitting: false,
      removing: false,
      leaving: false,
      editing: false,
      event: null,
      members: null,
      invites: null,
      errors: {
        title: false,
        from: false,
        to: false
      }
    }
  },

  computed: {
    ...mapGetters({
      userId: 'auth/getUserId'
    }),

    huntAreas () {
      const huntAreas = this.$store.getters['huntarea/areas']
      if (huntAreas !== null) {
        return huntAreas.slice(0).sort((a, b) => a.name.localeCompare(b.name))
      }
      return null
    },

    selectedHuntArea () {
      let huntAreas = this.$store.getters['huntarea/areas']
      if (huntAreas === null) {
        huntAreas = []
      }

      const i = huntAreas.findIndex(huntArea => huntArea.id === this.event.huntAreaId)
      return i !== -1 ? huntAreas[i] : null
    },

    title () {
      if (this.selectedEvent.id === null) {
        return this.$i18n.t('calendar.activity.new')
      } else if (this.selectedHuntArea !== null && this.event !== null) {
        return this.selectedHuntArea.name + ': ' + this.event.title
      }
      return ''
    },

    canRemove () {
      return this.event !== null && this.event.id !== null && this.editing && (this.isOwner || this.isAdmin)
    },

    canEdit () {
      return this.event !== null && this.event.id !== null && !this.editing && (this.isOwner || this.isAdmin)
    },

    canLeave () {
      return !this.editing && this.isMember && this.hasAccepted && !(this.isAdmin || this.isOwner)
    },

    hasAccepted () {
      const invites = this.invites || []
      const invite = invites.filter(invite => invite.invitedUserId === this.userId && invite.hasAnswered && invite.hasAccepted)
      return invite.length > 0
    },

    isOwner () {
      return this.event.createdBy === this.userId
    },

    isAdmin () {
      if (this.members !== null) {
        const i = this.members.findIndex(member => member.id === this.userId)
        return i !== -1 ? this.members[i].role === 'admin' : false
      }

      return false
    },

    createdBy () {
      if (this.members !== null) {
        const i = this.members.findIndex(member => member.id === this.event.createdBy)
        return i !== -1 ? this.members[i] : null
      }

      return null
    },

    createdByLabel () {
      if (this.event !== null && this.event.id !== null) {
        const createdBy = this.createdBy !== null ? this.createdBy.getFullName() : this.$t('calendar.activity.unknown')

        return this.$t('calendar.activity.createdBy', {
          name: createdBy,
          date: dayjs(this.event.createdAt).format('YYYY-MM-DD HH:mm')
        })
      }

      return null
    },

    isMember () {
      const members = this.members || []
      const i = members.findIndex(member => member.id === this.userId)
      return i !== -1 ? members[i].selected : false
    }
  },

  async created () {
    if (this.selectedEvent.id !== null) {
      await this.readEvent()
      await this.readMembers()
      this.readInvites()
    } else {
      this.event = new CalendarEventModel(this.selectedEvent)
      this.invites = []
      this.editing = true

      if (this.huntAreas.length > 0) {
        this.event.huntAreaId = this.huntAreas[0].id
        await this.readMembers()
        this.selectCurrentUser()
      } else {
        this.members = []
      }
    }
  },

  methods: {
    async readEvent () {
      this.event = await CalendarEventService.readById(this.selectedEvent.huntAreaId, this.selectedEvent.id)
    },

    async readMembers () {
      this.members = null
      this.members = await this.$store.dispatch('huntarea/members/read', this.selectedHuntArea.id)
    },

    async readInvites () {
      this.invites = await CalendarEventInviteService.read(this.event.huntAreaId, this.event.id)

      this.invites.forEach(invite => {
        const i = this.members.findIndex(member => member.id === invite.invitedUserId)
        if (i !== -1) {
          this.members[i].selected = true
        }
      })
    },

    edit () {
      this.editing = true
    },

    close () {
      this.$emit('close')
    },

    async remove () {
      const response = await this.$dialog.confirm({
        title: this.$i18n.t('calendar.activity.removeTitle'),
        message: this.$i18n.t('calendar.activity.removeMessage'),
        ok: this.$i18n.t('general.remove')
      })

      if (response.ok) {
        this.removing = true

        try {
          await this.$store.dispatch('calendar/removeEvent', this.event)
          this.close()
        } catch (error) {
          this.$notification.danger(this.$i18n.t('calendar.activity.removeFailedErrorMessage'))
        }

        this.removing = false
      }
    },

    async submit () {
      if (this.validate()) {
        this.submitting = true

        try {
          this.event.status = this.isMember ? CALENDAR_EVENT_STATUS_MEMBER : CALENDAR_EVENT_STATUS_NOT_INVITED
          this.event = await this.$store.dispatch('calendar/saveEvent', this.event)

          await this.saveMembers()

          this.close()
        } catch (error) {
          this.$notification.danger(this.$i18n.t('calendar.activity.saveFailedErrorMessage'))
          console.error(error)
        }

        this.submitting = false
      }
    },

    validate () {
      this.errors.title = this.event.title.length === 0
      this.errors.from = this.event.from === null
      this.errors.to = this.event.to === null

      return this.event.huntAreaId !== null && !this.errors.title && !this.errors.from && !this.errors.to
    },

    async saveMembers () {
      let promises = []

      this.members.forEach(member => {
        if (member.selected && !this.isAlreadyInvited(member)) {
          promises.push(this.inviteMember(member))
        }
      })

      this.invites.forEach(invite => {
        if (!this.isStillInvited(invite)) {
          promises.push(this.removeMember(invite))
        }
      })

      if (promises.length > 0) {
        await Promise.all(promises)
      }
    },

    async onLeave () {
      this.leaving = true

      try {
        await CalendarEventInviteService.delete(this.event.huntAreaId, this.event.id, this.userId)
        this.close()
      } catch (error) {
        this.$notification.danger(this.$i18n.t('general.unknownErrorMessage'))
      } finally {
        this.leaving = false
      }
    },

    isAlreadyInvited (member) {
      const i = this.invites.findIndex(invite => invite.invitedUserId === member.id)
      return i !== -1
    },

    isStillInvited (invite) {
      const i = this.members.findIndex(member => member.id === invite.invitedUserId)
      return i !== -1 ? this.members[i].selected : false
    },

    inviteMember (member) {
      const language = this.$store.getters['ui/language']
      return CalendarEventInviteService.invite(this.event.huntAreaId, this.event.id, member.id, language)
    },

    removeMember (invite) {
      return CalendarEventInviteService.delete(this.event.huntAreaId, this.event.id, invite.invitedUserId)
    },

    async selectHuntArea (huntArea) {
      this.event.huntAreaId = huntArea.id

      await this.readMembers()

      if (this.event.id === null) {
        this.selectCurrentUser()
      }
    },

    selectCurrentUser () {
      const userId = this.$store.getters['auth/getUserId']

      const i = this.members.findIndex(member => member.id === userId)
      if (i !== -1) {
        this.members[i].selected = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-card {
  max-width: 1024px;
  width: calc(100vw - 40px);

  @media screen and (max-width: 768px) {
    max-width: calc(100vw - 40px);
  }
}

.modal-card-head {
  background-color: white !important;
  border-bottom: none;
  align-items: flex-start;

  .modal-card-title {
    line-height: 1.5;
    width: calc(100% - 20px);
  }
}

.modal-card-foot {
  background-color: white !important;
  border-top: none;
}

.modal-card-foot .level {
  width: 100%;
}

.members .level {
  margin-bottom: 1rem;
}

.placeholder-title {
  color: lightgrey;
  background-color: lightgrey;
  width: 50px;
}

.created-by {
  display: block;
  margin-top: 10px;
  font-size: 14px;
  color: #7a7a7a !important;
}
</style>
