<template>
  <TableRow>
    <TableCell class="is-narrow">
      <div>
        <img :src="icon" />
      </div>
      <small>{{ title }}</small>
    </TableCell>

    <TableCell class="is-narrow">
      <div v-for="(game, i) in event.games" :key="i">
        {{ game.count }} {{ game.animalName }}
      </div>
    </TableCell>

    <TableCell class="is-narrow">
      {{ occurredAt }}
    </TableCell>

    <TableCell class="is-narrow">
      <span v-if="event.poiName !== null">
        {{ event.poiName }}
      </span>

      <span v-else-if="event.coordinate !== null">
        {{ coordinate.lat }}, {{ coordinate.lng }}
      </span>
    </TableCell>

    <TableCell class="has-text-grey">
      <small>{{ event.comment }}</small>
    </TableCell>
  </TableRow>
</template>

<script>
import { getMarkerTypeIconUrl } from '@/api/map/markers/markers'

export default {
  props: {
    event: {
      type: Object,
      required: true
    }
  },

  computed: {
    icon () {
      return getMarkerTypeIconUrl(this.event.type)
    },

    title () {
      const titles = {
        kill: this.$i18n.t('hunt.events.killShort'),
        observation: this.$i18n.t('hunt.events.observationShort')
      }
      return titles[this.event.type]
    },

    occurredAt () {
      if (this.event.timestamp !== null) {
        return this.$dayjs(this.event.timestamp).format('HH:mm')
      }
      return ''
    },

    coordinate () {
      return {
        lat: this.event.coordinate.lat.toFixed(4),
        lng: this.event.coordinate.lng.toFixed(4)
      }
    }
  }
}
</script>
