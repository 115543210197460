<template>
  <section>
    <CalendarSidebarHuntAreaSection />
    <CalendarSidebarInvitesSection />
  </section>
</template>

<script>
import CalendarSidebarHuntAreaSection from './CalendarSidebarHuntAreaSection.vue'
import CalendarSidebarInvitesSection from './CalendarSidebarInvitesSection.vue'

export default {
  components: {
    CalendarSidebarHuntAreaSection,
    CalendarSidebarInvitesSection
  }
}
</script>
