<template>
  <CalendarSidebarSection
    :title="$t('calendar.huntAreas')"
    :loading="isLoading"
    :expanded="false"
    :item-count="itemCount">
    <div v-if="!isLoading && huntAreas.length > 0">
      <CalendarSidebarHuntAreaSectionItem :hunt-area="selectAllHuntArea" @selected="selectAllChanged" />
      <CalendarSidebarHuntAreaSectionItem
        v-for="huntArea in huntAreas"
        :key="huntArea.id"
        :hunt-area="huntArea"
        @selected="huntAreaSelectionChanged">
      </CalendarSidebarHuntAreaSectionItem>
    </div>

    <Paragraph v-else class="has-text-grey" :text="$t('calendar.noHuntAreasText')" />
  </CalendarSidebarSection>
</template>

<script>
import CalendarSidebarSection from './CalendarSidebarSection.vue'
import CalendarSidebarHuntAreaSectionItem from './CalendarSidebarHuntAreaSectionItem.vue'

import HuntAreaModel from '@/api/huntarea/huntAreaModel'

export default {
  components: {
    CalendarSidebarSection,
    CalendarSidebarHuntAreaSectionItem
  },

  data () {
    return {
      selectAllHuntArea: new HuntAreaModel({
        name: this.$i18n.t('calendar.showAll')
      })
    }
  },

  computed: {
    huntAreas () {
      const huntAreas = this.$store.getters['huntarea/areas']

      if (huntAreas !== null) {
        return huntAreas.slice(0).sort((a, b) => a.name.localeCompare(b.name))
      }

      return null
    },

    isLoading () {
      return this.huntAreas === null
    },

    itemCount () {
      return this.huntAreas !== null ? this.huntAreas.length : 0
    }
  },

  methods: {
    selectAllChanged () {
      this.selectAll(this.selectAllHuntArea.selected)
    },

    huntAreaSelectionChanged (huntArea) {
      const selectedHuntAreas = this.huntAreas.filter(huntArea => huntArea.selected)
      this.selectAllHuntArea.selected = selectedHuntAreas.length > 0
    },

    selectAll (select) {
      this.huntAreas.forEach(huntArea => {
        huntArea.selected = select
      })
    }
  }
}
</script>

<style scoped>
.accordion-title {
  margin-bottom: 0 !important;
}
</style>
