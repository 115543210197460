import dayjs from 'dayjs'

class CalendarEventInviteModel {
  constructor (context) {
    const data = Object.assign({}, context)

    this.id = data.id || null

    this.invitedAt = data.invitedAt !== null ? dayjs(data.invitedAt).toDate() : null
    this.invitedUserId = data.invitedUserId || null
    this.invitedUserName = data.invitedUserName || ''
    this.invitedBy = data.invitedBy || null

    this.answeredAt = data.answeredAt !== null ? dayjs(data.answeredAt).toDate() : null
    this.hasAnswered = data.hasAnswered !== undefined ? data.hasAnswered : false
    this.hasAccepted = data.hasAccepted !== undefined ? data.hasAccepted : false

    this.token = data.token || ''
  }

  toJSON () {
    return {
      invitedAt: this.invitedAt,
      invitedUserId: this.invitedUserId,
      invitedUserName: this.invitedUserName,
      invitedBy: this.invitedBy,
      answeredAt: this.answeredAt,
      hasAnswered: this.hasAnswered,
      hasAccepted: this.hasAccepted,
      token: this.token
    }
  }
}

export default CalendarEventInviteModel
