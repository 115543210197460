<template>
  <div v-if="event !== null">
    <input
      v-if="edit"
      v-model="event.title"
      :placeholder="$t('calendar.activity.titlePlaceholder')"
      class="input"
      type="text"
      required />

    <span v-else>{{ event.title }}</span>

    <p v-if="error" class="help is-danger">{{ $t('calendar.activity.titleRequired') }}</p>
  </div>

  <ContentPlaceholders v-else>
    <ContentPlaceholdersText :lines="1" />
  </ContentPlaceholders>
</template>

<script>
export default {
  props: {
    event: {
      type: Object,
      default: () => {}
    },

    edit: {
      type: Boolean,
      default: false
    },

    error: {
      type: Boolean,
      default: false
    }
  }
}
</script>
