<template>
  <CalendarDialogSection :title="$t('report.events.title')">
    <CalendarReportDialogEventSectionTable v-if="events.length > 0">
      <CalendarReportDialogEventSectionTableRow
        v-for="(event, i) in events"
        :key="i"
        :event="event">
      </CalendarReportDialogEventSectionTableRow>
    </CalendarReportDialogEventSectionTable>

    <Paragraph
      v-else
      class="has-text-grey"
      :text="$t('report.events.none')">
    </Paragraph>
  </CalendarDialogSection>
</template>

<script>
import CalendarDialogSection from '../dialog/CalendarDialogParticipantSection.vue'

import CalendarReportDialogEventSectionTable from './CalendarReportDialogEventSectionTable.vue'
import CalendarReportDialogEventSectionTableRow from './CalendarReportDialogEventSectionTableRow.vue'

export default {
  components: {
    CalendarDialogSection,
    CalendarReportDialogEventSectionTable,
    CalendarReportDialogEventSectionTableRow
  },

  props: {
    events: {
      type: Array,
      required: true
    }
  }
}
</script>
