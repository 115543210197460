<template>
  <Titlebar :isLoading="isLoading">
    <div slot="title">
      <h5 class="title is-5 is-marginless">{{ $t('calendar.title') }}</h5>
      <span class="subtitle is-6 text-is-capitalized">{{ headerProps.periodLabel }}</span>
    </div>

    <div slot="local">
      <ButtonGroup segmented :class="{ 'is-dimmed is-disabled': isLoading }">
        <Button
          :disabled="!headerProps.previousPeriod"
          @click="onInput(headerProps.previousPeriod)">
          &laquo;
          {{ month(headerProps.previousPeriod) }}
        </Button>

        <Button
          :disabled="!headerProps.nextPeriod"
          @click="onInput(headerProps.nextPeriod)">
          {{ month(headerProps.nextPeriod) }}
          &raquo;
        </Button>
      </ButtonGroup>

      <div class="upgrade-subscription-button-container push-right">
        <ButtonGroup segmented class="push-left-xxs">
          <Button
            type="secondary"
            :disabled="!hasHuntAreas || !hasPro"
            @click="create">
            <Icon v-if="showRequiresProBadge" name="icon-lock" />
            {{ $t('calendar.activity.new') }}
          </Button>

          <Button
            type="primary"
            :disabled="!hasHuntAreas || !hasPro"
            @click="planHunt">
            <Icon v-if="showRequiresProBadge" name="icon-lock" />
            {{ $t('hunt.planHunt') }}
          </Button>
        </ButtonGroup>

        <UpgradeSubscriptionBadge class="upgrade-subscription-badge" />
      </div>
    </div>
  </Titlebar>
</template>

<script>
import dayjs from 'dayjs'
import { mapGetters } from 'vuex'
import * as _ from '@/assets/libs/utils/'

import UpgradeSubscriptionBadge from '@/modules/subscription/components/UpgradeSubscriptionBadge.vue'

export default {
  components: {
    UpgradeSubscriptionBadge
  },

  props: {
    headerProps: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      huntAreaId: this.$route.params.huntAreaId
    }
  },

  computed: {
    ...mapGetters({
      huntAreas: 'huntarea/areas',
      isLoading: 'calendar/isLoading',
      isLoadingSubscription: 'subscription/isLoading',
      hasPro: 'subscription/hasPro'
    }),

    hasHuntAreas () {
      return this.huntAreas && this.huntAreas.length > 0
    },

    showRequiresProBadge () {
      return !this.hasPro && !this.isLoadingSubscription
    }
  },

  methods: {
    month (date) {
      return _.capitalize(dayjs(date).format('MMM'))
    },

    onInput (d) {
      this.$emit('input', d)
    },

    create () {
      this.$emit('new-activity')
    },

    planHunt () {
      this.$emit('plan-hunt')
    }
  }
}
</script>

<style lang="scss" scoped>
.button .icon {
  margin-right: 0.25rem !important;
}

.upgrade-subscription-button-container {
  display: inline-block;
  position: relative;
}

.upgrade-subscription-badge {
  position: absolute;
  top: -15px;
  right: -16px;
}
</style>
