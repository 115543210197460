<template>
  <TableRow>
    <TableCell>
      {{ assignment.getFullName() }}
    </TableCell>

    <TableCell :class="{ 'has-text-grey': assignment.role === 'unassigned' }">
      {{ roleName }}
    </TableCell>

    <TableCell>
      <span v-if="assignment.role === 'pass' && hasMarker">
        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item">
              <img :src="markerIcon" />
            </div>

            <div class="level-item">
              {{ marker.name }}
            </div>
          </div>
        </div>
      </span>
    </TableCell>
  </TableRow>
</template>

<script>
import { mapGetters } from 'vuex'
import { getMarkerTypeIconUrl } from '@/api/map/markers/markers'

export default {
  props: {
    assignment: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    ...mapGetters({
      markers: 'calendar/selectedHunt/getMarkers'
    }),

    hasMarker () {
      return this.assignment.markerId !== null
    },

    marker () {
      const markers = this.markers || []
      const i = markers.findIndex(marker => marker.id === this.assignment.markerId)
      return i !== -1 ? markers[i] : null
    },

    markerIcon () {
      return getMarkerTypeIconUrl(this.marker.type)
    },

    roleName () {
      const roles = {
        dog: this.$i18n.t('hunt.roles.dogHandler'),
        drive: this.$i18n.t('hunt.roles.driveHunter'),
        pass: this.$i18n.t('hunt.roles.standShooter'),
        unassigned: this.$i18n.t('hunt.roles.unassigned')
      }

      return this.assignment.role !== null ? roles[this.assignment.role] : this.$i18n.t('hunt.roles.unassigned')
    }
  }
}
</script>
