<template>
  <div v-if="invites !== null" class="invites">
    <div v-if="invites.length > 0">
      <div v-for="invite in invites" :key="invite.id">
        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item">
              <ProfilePicture
                :userId="Number(invite.invitedUserId)"
                :name="invite.invitedUserName"
                size="small"
                round />
            </div>

            <div class="level-item push-left-xs">
              <div>
                <p class="is-marginless">{{ invite.invitedUserName }}</p>

                <span class="is-size-7 is-marginless">
                  <span v-if="!invite.hasAnswered">{{ $t('calendar.activity.invitedNoAnswer') }}</span>
                  <span v-if="invite.hasAnswered">
                    <span v-if="invite.hasAccepted" class="has-text-success">{{ $t('calendar.activity.invitedAccepted') }}</span>
                    <span v-if="!invite.hasAccepted" class="has-text-danger">{{ $t('calendar.activity.invitedDeclined') }}</span>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <span v-else class="has-text-grey">{{ $t('calendar.activity.noParticipants') }}</span>
  </div>

  <div v-else>
    <ContentPlaceholders
      v-for="i in 5"
      :key="i"
      :rounded="true"
      class="push-bottom-xs">
      <ContentPlaceholdersHeading :img="true" />
    </ContentPlaceholders>
  </div>
</template>

<script>
export default {
  props: {
    invites: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style scoped>
.invites {
  max-height: 420px;
  overflow-y: auto;
}

.invites .level {
  margin-bottom: 1rem;
}

.invites .invite-name {
  margin-bottom: 0;
}
</style>
