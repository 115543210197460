<template>
  <CalendarDialogSection :title="$t('report.participants.title')">
    <CalendarDialogParticipantSectionItem
      v-for="(participant, i) in participants"
      :key="i"
      :user-id="participant.userId"
      :name="participant.name">
    </CalendarDialogParticipantSectionItem>

    <Paragraph
      v-if="participants.length === 0"
      class="has-text-grey"
      :text="$t('report.participants.none')">
    </Paragraph>
  </CalendarDialogSection>
</template>

<script>
import CalendarDialogSection from '../dialog/CalendarDialogParticipantSection.vue'
import CalendarDialogParticipantSectionItem from '../dialog/CalendarDialogParticipantSectionItem.vue'

export default {
  components: {
    CalendarDialogSection,
    CalendarDialogParticipantSectionItem
  },

  props: {
    participants: {
      type: Array,
      required: true
    }
  }
}
</script>
